/**
 * Takes a list of ImageSource objects and returns one array per image format type.
 *
 */
export default function useImageFormats(srcSet: ImageSource[]): {
  type: string
  srcsetstring: string
}[] {
  if (!srcSet) {
    return []
  }
  const formatPriority = ["image/webp", "image/jpeg", "image/png"]
  const formats = [...new Set(srcSet.map(({ type }) => type))]
  formats.sort((a, b) => {
    return formatPriority.indexOf(a) - formatPriority.indexOf(b)
  })
  return formats.map((format) => ({
    type: format,
    srcsetstring: srcSet
      .filter(({ type }) => type === format)
      .map(({ url, desc }) => `${url} ${desc}`)
      .join(", "),
  }))
}
